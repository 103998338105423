import React from "react";
import { Link } from "react-router-dom";
import FooterBlack from "../components/footerAdmin";
import HeaderBrta from "../components/headerBrta";
import loadjs from "loadjs";
import axios from "axios"; // Import axios for API calls
import MobileNav from "../components/mobilenav";

class UserListAdminBrta extends React.Component {
  state = {
    users: [], // To hold the fetched user data
    loading: true, // To handle loading state
    error: null, // To handle error state
    activeFilter: "all", // To track the active filter
    searchQuery: "", // To track the search query
  };

  componentDidMount() {
    // Check if the user is authenticated
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true"; 

    if (!isAuthenticated) {
      this.setState({ 
        loading: false, 
        error: "You are not authenticated. Please Logout." 
      }); 
      return; 
    }

    loadjs("./js/main.js");
    loadjs("./js/plugins.js");

    // Fetch user data using axios
    axios
      .get("https://api.holoapp.tech:3000/api/get-users") // Replace with your API endpoint
      .then((response) => {
        this.setState({
          users: response.data, // Populate users data
          loading: false, // Set loading to false when data is fetched
        });
      })
      .catch((error) => {
        this.setState({
          error: "Failed to fetch user data", // Set error if the request fails
          loading: false, // Set loading to false even on error
        });
      });
  }

  // Function to handle filter clicks
  handleFilterClick = (filter) => {
    this.setState({ activeFilter: filter });
  };

  // Function to handle search input changes
  handleSearchChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  // Function to filter users based on the active filter and search query
  getFilteredUsers = () => {
    const { users, activeFilter, searchQuery } = this.state;

    let filteredUsers = users;

    // Apply the active filter
    switch (activeFilter) {
      case "all":
        break;
      case "jatri":
        filteredUsers = filteredUsers.filter((user) => user.user_created_at);
        break;
      case "chalok":
        filteredUsers = filteredUsers.filter((user) => user.driver_created_at);
        break;
      case "jatriApp":
        filteredUsers = filteredUsers.filter((user) => user.app === "jatri");
        break;
      case "chalokApp":
        filteredUsers = filteredUsers.filter((user) => user.app === "chalok");
        break;
      case "incompleteJatri":
        filteredUsers = filteredUsers.filter(
          (user) => user.app === "jatri" && !user.user_created_at
        );
        break;
      case "incompleteChalok":
        filteredUsers = filteredUsers.filter(
          (user) => user.app === "chalok" && !user.driver_created_at
        );
        break;
      case "chalokConverted":
        filteredUsers = filteredUsers.filter(
          (user) => user.app === "jatri" && user.driver_created_at
        );
        break;
      default:
        break;
    }

    // Apply the search query
    if (searchQuery) {
      filteredUsers = filteredUsers.filter((user) =>
        user.contact?.includes(searchQuery)
      );
    }

    return filteredUsers;
  };

  render() {
    const { users, loading, error, activeFilter, searchQuery } = this.state;

    // Calculate the totals
    const totalUsers = users.length;
    const totalJatri = users.filter((user) => user.user_created_at).length;
    const totalChalok = users.filter((user) => user.driver_created_at).length;

    // Get filtered users based on the active filter and search query
    const filteredUsers = this.getFilteredUsers();

    return (
      <>
        <HeaderBrta />
        <MobileNav />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image admin-header-height"
          data-bg="img/bg/dhaka.jpeg"
        >
          <div className="container"></div>
        </div>
        {/* BREADCRUMB AREA END */}

        {/* PAGE DETAILS AREA START */}
        <div className="ltn__page-details-area ltn__blog-details-area mb-120">
          <div className="container">
            <h1 className="text-center mb-4">User List</h1>
            {loading ? (
              <div className="text-center">Loading...</div>
            ) : error ? (
              <div className="text-center text-danger">{error}</div>
            ) : (
              <>
                {/* Display total counts */}
                <div className="row mb-4">
                  <div className="col-md-4">
                    <h5
                      onClick={() => this.handleFilterClick("all")}
                      style={{ cursor: "pointer", color: activeFilter === "all" ? "blue" : "black" }}
                    >
                      Total Users: {totalUsers}
                    </h5>
                  </div>
                  <div className="col-md-4">
                    <h5
                      onClick={() => this.handleFilterClick("jatri")}
                      style={{ cursor: "pointer", color: activeFilter === "jatri" ? "blue" : "black" }}
                    >
                      Total Jatri: {totalJatri}
                    </h5>
                  </div>
                  <div className="col-md-4">
                    <h5
                      onClick={() => this.handleFilterClick("chalok")}
                      style={{ cursor: "pointer", color: activeFilter === "chalok" ? "blue" : "black" }}
                    >
                      Total Chalok: {totalChalok}
                    </h5>
                  </div>
                </div>

                {/* Search input for filtering by contact */}
                <div className="row mb-4">
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by contact"
                      value={searchQuery}
                      onChange={this.handleSearchChange}
                    />
                  </div>
                </div>

                {/* Display user data in the table */}
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>User ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Contact</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers.map((user) => (
                      <tr key={user.userID}>
                        <td>{user.userID}</td>
                        <td>{user.name}</td>
                        <td>{user.email || "N/A"}</td>
                        <td>{user.contact || "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
        {/* PAGE DETAILS AREA END */}

        {/* FOOTER AREA START */}
        <FooterBlack />
        {/* FOOTER AREA END */}
      </>
    );
  }
}

export default UserListAdminBrta;