import React, {Component} from "react";
import {Link} from "react-router-dom";
import loadjs from "loadjs";
import HeaderBlack from "../components/headerblack";
import MobileNav from "../components/mobilenav";
import FooterBlack from "../components/footerblack";
import {PDFDownloadLink, Document, Page} from '@react-pdf/renderer'

const MyDoc = () => (
    <Document>
        <Page>
            // My document data
        </Page>
    </Document>
);

class More extends Component {

    componentDidMount() {

        loadjs('./js/main.js');

        loadjs('./js/plugins.js');
    }

    render() {
        return (
            <>
                <HeaderBlack/>
                <MobileNav/>
                {/* BREADCRUMB AREA START */}
                <div
                    className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
                    data-bg="img/bg/dhaka.jpeg"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                                    <div className="section-title-area ltn__section-title-2">
                                        <h6 className="section-subtitle ltn__secondary-color">
                                            Welcome to Holo
                                        </h6>
                                        <h1 className="section-title white-color">What We Have</h1>
                                    </div>
                                    <div className="ltn__breadcrumb-list">
                                        <ul>
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li>Our App</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* BREADCRUMB AREA END */}

                {/* SERVICE AREA START (Service 1) */}
                <div className="ltn__service-area section-bg-1 pt-115 pb-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-area ltn__section-title-2 text-center">
                                    <h6 className="section-subtitle ltn__secondary-color">Apps</h6>
                                    <h1 className="section-title">What We Have<span>.</span></h1>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="ltn__service-item-2 white-bg">
                                    <div className="service-item-icon">
                                        <i className="fas fa-user"/>
                                    </div>
                                    <div className="service-item-brief">
                                        <h3 className="ltn__secondary-color">App For Jatri</h3>
                                        {/* <h3><a href="service-details.html">There are many variations of passages of Lorem.</a></h3> */}
                                        <ul>
                                            <li><span className="fas fa-check"/> Easy to use.</li>
                                            <li><span className="fas fa-check"/> Instant ride.</li>
                                            <li><span className="fas fa-check"/> Safety ensured.</li>
                                            <li><strong><span style={{ marginRight: '10px' }}>App -</span>  
                                            <a href="https://play.google.com/store/apps/details?id=com.holoerjatri" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                                                Jatri
                                            </a>                                                
                                                </strong>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="ltn__service-item-2 white-bg">
                                    <div className="service-item-icon">
                                        <i className="fas fa-motorcycle"/>
                                    </div>
                                    <div className="service-item-brief">
                                        <h3 className="ltn__secondary-color">App For Chalok</h3>
                                        {/* <h3><a href="service-details.html">majority have suffered alteration in some form.</a></h3> */}
                                        <ul>
                                            <li><span className="fas fa-check"/> Easy to use.</li>
                                            <li><span className="fas fa-check"/> Instant payment.</li>
                                            <li><span className="fas fa-check"/> No commission required.</li>
                                            <li><strong><span style={{ marginRight: '10px' }}>App -</span>  
                                            <a href="https://play.google.com/store/apps/details?id=com.holoerchalok" target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                                                Chalok
                                            </a>                                                
                                                </strong>
                                            </li>
                                        </ul>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-12">
                            <aside className="sidebar-area blog-sidebar ltn__right-sidebar">
                                {/* Author Widget */}
                                <div className="widget ltn__author-widget">

                                    <div className="section-title-area ltn__section-title-2 text-center">
                                        {/*<h6 className="section-subtitle ltn__secondary-color">Apps</h6>*/}
                                        <h3 className="section-title">Traffic Rules</h3>
                                    </div>

                                    <div className="widget ltn__banner-widget" style={{textAlign: 'center'}}>
                                        <img src="img/service/1-69bf349b73.jpeg" alt="Banner"/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <img src="img/service/23.jpeg" alt="Banner"/>
                                    </div>
                                </div>
                                <div className="widget ltn__author-widget">

                                    <div className="section-title-area ltn__section-title-2 text-center">
                                        {/*<h6 className="section-subtitle ltn__secondary-color">Apps</h6>*/}
                                        <h3 className="section-title">Covid-19</h3>
                                    </div>

                                    <div className="widget ltn__banner-widget" style={{textAlign: 'center'}}>
                                        <img src="img/service/corona.jpeg" alt="Banner"/>

                                    </div>
                                </div>
                                {/*<div className="widget ltn__author-widget">*/}
                                {/*    <div className="section-title-area ltn__section-title-2 text-center">*/}
                                {/*        /!*<h6 className="section-subtitle ltn__secondary-color">Apps</h6>*!/*/}
                                {/*        <h3 className="section-title">Enlistment Flowchart</h3>*/}
                                {/*    </div>*/}

                                {/*    <div className="widget ltn__banner-widget" style={{textAlign: 'center'}}>*/}
                                {/*        <img src="img/service/enlistment flowchart-bangla.drawio.png" alt="Banner"/>*/}
                                {/*        <br/>*/}
                                {/*        <br/>*/}
                                {/*        <br/>*/}
                                {/*        <img src="img/service/enlistment flowchart-english.drawio.png" alt="Banner"/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="widget ltn__author-widget">
                                    <div className="section-title-area ltn__section-title-2 text-center">
                                        {/*<h6 className="section-subtitle ltn__secondary-color">Apps</h6>*/}
                                        <h3 className="section-title">Ride Sharing Service Guideline 2017
                                            (Gazette)</h3>
                                    </div>

                                    <div className="widget ltn__banner-widget" style={{textAlign: 'center'}}>


                                        <a href="img/service/Ride Sharing Service Guideline 2017 (Gazette).pdf"
                                           download="Ride Sharing Service Guideline 2017 (Gazette).pdf"><img
                                            src="img/service/file.png" width="100" height="100"/></a>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
                {/* SERVICE AREA END */}
                <FooterBlack/>
            </>

        );
    }
}

export default More;
